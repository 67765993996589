<template>
  <div class="columns is-multiline has-padding-50 is-marginless">
    <div class="column is-4-widescreen is-12-mobile is-12-tablet">
      <card>
        <template slot="header">
          <p class="card-header-title">
            <strong>Promotion</strong>
          </p>
        </template>
        <template slot="body">
          <promotion-details
            ref="promotionDetails"
            :promotion-id="promotionId"
            @processing="details.processing = $event"
            @valid="details.isValid = $event"
            @loading="details.loading = $event"
            @hasChange="details.hasChange = $event"
          />
        </template>

        <template slot="footer">
          <div class="has-padding-75 is-stretched has-text-right">
            <button
              :class="{ 'is-loading': details.processing }"
              :disabled="
                !details.isValid ||
                  details.loading ||
                  details.processing ||
                  !details.hasChange
              "
              type="submit"
              class="button is-success"
              @click="$refs.promotionDetails.save()"
            >
              Save
            </button>
          </div>
        </template>
      </card>
    </div>

    <div class="column is-8-widescreen is-12-mobile is-12-tablet">
      <div class="columns is-multiline">
        <div class="column is-12">
          <card>
            <template slot="header">
              <p class="card-header-title">
                <strong>Coupons</strong>
              </p>
            </template>
            <template slot="body">
              <coupons-table :promotion-id="promotionId" />
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Promotion",
  components: {
    "promotion-details": () => import("@shared/promotions/_promotionDetails"),
    "coupons-table": () => import("@shared/promotions/_couponsTable")
  },
  data() {
    return {
      promotionId: this.$route.params.promotionId,
      details: {
        loading: false,
        processing: false,
        isValid: false,
        hasChange: true
      },
      provision: {
        loading: false,
        processing: false,
        isValid: false,
        hasChange: true
      }
    };
  },
  computed: {
    promotion() {
      return this.$store.getters["promotion/promotion"](this.promotionId);
    }
  },
  created() {
    this.$store.dispatch("promotion/observePromotion", {
      promotionId: this.promotionId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("promotion/unobservePromotion", {
      promotionId: this.promotionId
    });
  }
};
</script>
